var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        "header-class": "header-class-modal-doc-package",
        size: "xl",
        "cancel-variant": "outline-primary",
        "button-size": "md",
        "ok-title":
          this.isActionCrud === this.addData
            ? _vm.FormMSG(26, "Add day to timesheet")
            : _vm.FormMSG(76, "Update day to timesheet"),
        "cancel-title": _vm.FormMSG(27, "Cancel"),
        "ok-variant": "primary",
        "hide-header-close": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "ok-disabled": _vm.disableFieldByStatus,
        "modal-class": "mui-animation",
        fade: false,
      },
      on: {
        ok: function ($event) {
          $event.preventDefault()
          return _vm.saveCurDay.apply(null, arguments)
        },
        show: function ($event) {
          _vm.editData && _vm.isEditMode
        },
        hidden: _vm.hideModalAddUpdate,
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function ({ close }) {
            return [
              _c("div", { staticClass: "d-flex flex-row w-100" }, [
                _c("div", { staticClass: "d-flex flex-row w-100" }, [
                  _c("h5", [
                    _vm._v(
                      _vm._s(
                        _vm.isActionCrud === _vm.addData
                          ? _vm.FormMSG(25, "Add work")
                          : _vm.FormMSG(77, "Update work")
                      )
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "d-flex flex-row w-100" }, [
                  _vm.isEditMode && _vm.editData !== null
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex w-80 align-items-center justify-content-end w-100",
                        },
                        [
                          !_vm.isFilmSingle
                            ? _c(
                                "span",
                                { staticClass: "border-encoded p-1" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.FormMSG(283, "Encoded by: ")) +
                                      " "
                                  ),
                                  _c("b", [
                                    _vm._v(_vm._s(_vm.encoder(_vm.editData))),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      class: _vm.editData && _vm.isEditMode ? "w-20" : "w-100",
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn-close",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.hideModalAddUpdate()
                            },
                          },
                        },
                        [_vm._v("×")]
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          },
        },
      ]),
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c("form-time-card", {
        attrs: {
          "use-form-in-modal": true,
          "use-form-for": "web",
          "time-cards": _vm.TsDays,
          "time-card-to-edit": _vm.editData.tsDay,
          "hide-alert": "",
          "hide-status": _vm.hideStatusFormTimeCard,
          "multiple-selection-date": _vm.mutlipleSelectionDateFormTimeCard,
          "hide-save-btn": "",
          "hide-person-selector": false,
          "hide-department-selector": false,
          "is-my-time-card": false,
          useFormInModal: _vm.useFormInModal,
          "screen-used-the-form": _vm.screenUsedTheForm,
          "user-id": _vm.currUserId,
          "submit-form": _vm.submitForm,
          "disable-user-select": _vm.update,
          "external-dates": _vm.externalDates,
          "use-view-planning": _vm.useViewPlanning,
          handleFormTimeCardSubmitSuccess: _vm.handleFormTimeCardSubmitSuccess,
          isActionCrud: _vm.isActionCrud,
        },
        on: {
          "form-time-card:udpate-date": _vm.getTsDays,
          "form-time-card:save-cur-day-success":
            _vm.handleFormTimeCardSubmitSuccess,
          "form-time-card:close-cur-day":
            _vm.handleFormTimeCardSubmitPresistent,
          "form-time-card:empty-list": _vm.handleFormTimeCardEmptyList,
          senddataAuthToSubmitFormSelectMultiple:
            _vm.senddataAuthToSubmitFormSelectMultiple,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }