var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { cols: "12", xl: "12" } },
        [
          _c("MonthSelect", {
            attrs: { DateDefaultSelect: _vm.DateDefaultSelect },
            on: { setDateDefaultSelect: _vm.setDateDefaultSelect },
          }),
        ],
        1
      ),
      _c(
        "b-col",
        { attrs: { cols: "12", xl: "12" } },
        [
          _c(
            "b-table-simple",
            {
              ref: "my-table",
              attrs: {
                small: "",
                "caption-top": "",
                responsive: "",
                "sticky-header": "600px",
                "sticky-column": "first",
              },
            },
            [
              _c(
                "b-thead",
                [
                  _c(
                    "b-tr",
                    [
                      _c(
                        "b-th",
                        {
                          staticClass: "text-center middle",
                          staticStyle: {
                            position: "sticky",
                            left: "0",
                            "z-index": "3",
                            "background-color": "#00a09c",
                            border: "1px solid #dee2e6",
                            color: "#ffff",
                          },
                        },
                        [
                          _c("div", [
                            _vm._v(_vm._s(this.FormMSG(1, "Crew member"))),
                          ]),
                        ]
                      ),
                      _vm._l(_vm.tsfields.slice(1), function (item) {
                        return _c(
                          "b-th",
                          {
                            key: _vm.generateSecureId(`${item.key}`),
                            staticClass: "text-center middle",
                            staticStyle: {
                              "background-color": "#00a09c",
                              border: "1px solid #dee2e6",
                              color: "#ffff",
                            },
                          },
                          [
                            _c("div", [_vm._v(_vm._s(item.label.split` `[0]))]),
                            _c("div", [_vm._v(_vm._s(item.label.split` `[1]))]),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "b-tbody",
                {
                  ref: "tableRef",
                  on: {
                    mousedown: _vm.startScrollingLeft,
                    mouseup: _vm.stopScrollingLeft,
                  },
                },
                [
                  _c(
                    "b-tr",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.setAllTimesheets.length === 0,
                          expression: "setAllTimesheets.length === 0",
                        },
                      ],
                    },
                    [
                      _c(
                        "b-td",
                        {
                          staticClass:
                            "text-center border-left init-background",
                          staticStyle: {
                            border: "0.005rem solid rgba(226, 230, 235, 0.85)",
                            height: "50px",
                          },
                          attrs: {
                            colspan:
                              _vm.tsfields.length === 0
                                ? 1
                                : _vm.tsfields.length,
                          },
                        },
                        [
                          _c("div", [
                            _c(
                              "div",
                              { staticClass: "text-muted fs-14 w-100" },
                              [
                                _vm._v(
                                  _vm._s(this.FormMSG(2, "No data available"))
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._l(_vm.setAllTimesheets, function (item) {
                    return _c(
                      "b-tr",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.setAllTimesheets.length !== 0,
                            expression: "setAllTimesheets.length !== 0",
                          },
                        ],
                        key: _vm.generateSecureId(`${Math.random()}`),
                      },
                      [
                        _c(
                          "b-td",
                          {
                            staticClass: "sticky-column-body",
                            staticStyle: {
                              border:
                                "0.005rem solid rgba(226, 230, 235, 0.85)",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-row justify-content-start align-items-center",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-column justify-content-start p-1",
                                    staticStyle: {
                                      width: "150px",
                                      height: "60px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "font-weight-bold fs-14" },
                                      [_vm._v(_vm._s(item.fullName))]
                                    ),
                                    _c("div", [
                                      _vm._v(_vm._s(item.departmentName)),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-column justify-content-start align-items-center pd-1",
                                  },
                                  [
                                    _c("div", {
                                      staticClass:
                                        "d-flex flex-row w-100 show-hover",
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-row w-100 show-hover",
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName:
                                                  "v-b-tooltip.hover.bottom.html",
                                                value: `${_vm.FormMSG(
                                                  13,
                                                  "Send all for validation"
                                                )}`,
                                                expression:
                                                  "`${FormMSG(13, 'Send all for validation')}`",
                                                modifiers: {
                                                  hover: true,
                                                  bottom: true,
                                                  html: true,
                                                },
                                              },
                                            ],
                                            staticClass:
                                              "btn bg-transparent border-0",
                                            attrs: {
                                              disabled:
                                                _vm.checkNotSubmitted(item),
                                              size: "sm",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.sendAllForValidation(
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              _vm.getLucideIcon(
                                                _vm.ICONS.SEND.name
                                              ),
                                              {
                                                tag: "component",
                                                attrs: {
                                                  color: _vm.ICONS.SEND.color,
                                                  size: 16,
                                                  "stroke-width": 2.25,
                                                },
                                              }
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._l(_vm.tsfields.slice(1), function (data) {
                          return _c(
                            "b-td",
                            {
                              key: _vm.generateSecureId(`${data.key}`),
                              class: _vm.getWorked(data.key, item.tsDays)
                                ? "init-background hover-not-empty"
                                : "edit-background hover-is-empty",
                              staticStyle: {
                                border:
                                  "0.005rem solid rgba(226, 230, 235, 0.85)",
                              },
                              attrs: {
                                id: _vm.generateSecureId(
                                  `${
                                    item.id +
                                    item.fullName +
                                    item.departmentName +
                                    item.functionName +
                                    data.key
                                  }`
                                ),
                              },
                              on: {
                                contextmenu: function ($event) {
                                  $event.preventDefault()
                                  _vm.getWorked(data.key, item.tsDays) &&
                                    _vm.showContextMenu(
                                      $event,
                                      item,
                                      _vm.getDataOnCell(data.key, item, item.id)
                                    )
                                },
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return ((e) =>
                                    _vm.handleAllActionPlanning(
                                      e,
                                      item,
                                      data.key
                                    )).apply(null, arguments)
                                },
                                mousedown: (e) =>
                                  _vm.startDrag(e, item, data.key),
                                mouseover: (e) =>
                                  _vm.selectCell(e, item, data.key),
                                mouseup: (e) => _vm.endDrag(e),
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex flex-column",
                                  staticStyle: {
                                    width: "100px",
                                    height: "100%",
                                    gap: "2px",
                                  },
                                },
                                [
                                  _vm.getWorked(data.key, item.tsDays)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex position-relative",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex w-100 justify-content-end",
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-tooltip",
                                                      rawName:
                                                        "v-b-tooltip.hover.left.html",
                                                      value: `${_vm.getLabelStatus(
                                                        _vm.getStatus(
                                                          data.key,
                                                          item.tsDays
                                                        )
                                                      )}`,
                                                      expression:
                                                        "`${getLabelStatus(getStatus(data.key, item.tsDays))}`",
                                                      modifiers: {
                                                        hover: true,
                                                        left: true,
                                                        html: true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass:
                                                    "btn-transparent text-color-rhapsody-in-blue",
                                                },
                                                [
                                                  _c("div", {
                                                    class: `status-${_vm.getStatusColor(
                                                      _vm.getStatus(
                                                        data.key,
                                                        item.tsDays
                                                      )
                                                    )}`,
                                                    staticStyle: {
                                                      "font-size": "0.7rem",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex position-absolute flex-row w-100 h-auto justify-center align-items-center flex-wrap mt-3 ml-3",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-content-center flex-column",
                                                },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-tooltip",
                                                          rawName:
                                                            "v-b-tooltip.hover.left.html",
                                                          value:
                                                            _vm.tooltipContentOvertime(
                                                              data.key,
                                                              item.tsDays
                                                            ),
                                                          expression:
                                                            "tooltipContentOvertime(data.key, item.tsDays)",
                                                          modifiers: {
                                                            hover: true,
                                                            left: true,
                                                            html: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "btn-transparent text-color-rhapsody-in-blue",
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          _vm.ICONS.INFO.name
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            color:
                                                              _vm.ICONS.INFO
                                                                .color,
                                                            size: 16,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c("div", [
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getWorked(
                                                        data.key,
                                                        item.tsDays
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getSalary(
                                                        data.key,
                                                        item.tsDays
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm.isBusy
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-center align-items-center",
                  staticStyle: { height: "20px", gap: "5px" },
                },
                [
                  _c("div", { staticClass: "loading" }, [
                    _vm._v(_vm._s(this.FormMSG(9, "Loading"))),
                  ]),
                  _c("div", { staticClass: "bars-5" }),
                ]
              )
            : _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-row justify-content-center align-items-center",
                  staticStyle: { height: "20px" },
                },
                [
                  _c("div", { staticClass: "end-of-list" }, [
                    _vm._v(_vm._s(this.FormMSG(10, "End of list"))),
                  ]),
                ]
              ),
          _vm.showMenu
            ? _c(
                "div",
                {
                  ref: "contextMenu",
                  staticClass: "context-menu",
                  style: { top: _vm.menuY + "px", left: _vm.menuX + "px" },
                  attrs: { id: "ContextMenu" },
                },
                [
                  _vm.isShowActionByStatus(this.selectedItem)
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-start align-items-center pd-1 menu-context",
                          on: {
                            click: function ($event) {
                              return _vm.onMenuItemClick(1)
                            },
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn bg-transparent border-0",
                              attrs: { size: "sm" },
                            },
                            [
                              _c(_vm.getLucideIcon(_vm.ICONS.PENCIL.name), {
                                tag: "component",
                                attrs: {
                                  color: _vm.ICONS.PENCIL.color,
                                  size: 16,
                                  "stroke-width": 2.25,
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", [
                            _vm._v(_vm._s(this.FormMSG(7, "Update"))),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isNotValitedItem(this.selectedItem)
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-start align-items-center pd-1 menu-context",
                          on: {
                            click: function ($event) {
                              return _vm.onMenuItemClick(2)
                            },
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn bg-transparent border-0",
                              attrs: { size: "sm" },
                            },
                            [
                              _c(_vm.getLucideIcon(_vm.ICONS.SEND.name), {
                                tag: "component",
                                attrs: {
                                  color: _vm.ICONS.SEND.color,
                                  size: 16,
                                  "stroke-width": 2.25,
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.FormMSG(12, "Send For Validation"))
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isValidated(this.selectedItem)
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-start align-items-center pd-1 menu-context",
                          on: {
                            click: function ($event) {
                              _vm.isValid
                                ? _vm.onMenuItemClick(2.1)
                                : _vm.isReject
                                ? _vm.onMenuItemClick(2.2)
                                : () => []
                            },
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn bg-transparent border-0",
                              attrs: { size: "sm" },
                            },
                            [
                              _vm.isValid
                                ? _c(
                                    _vm.getLucideIcon(
                                      _vm.ICONS.CHECK_SQUARE.name
                                    ),
                                    {
                                      tag: "component",
                                      attrs: {
                                        color: _vm.ICONS.CHECK_SQUARE.color,
                                        size: 16,
                                        "stroke-width": 2.25,
                                      },
                                    }
                                  )
                                : _vm._e(),
                              _vm.isReject
                                ? _c(
                                    _vm.getLucideIcon(_vm.ICONS.X_CIRCLE.name),
                                    {
                                      tag: "component",
                                      attrs: {
                                        color: _vm.ICONS.X_CIRCLE.color,
                                        size: 16,
                                        "stroke-width": 2.25,
                                      },
                                    }
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.isValid
                                  ? `${_vm.FormMSG(14, "Validate")}`
                                  : `${_vm.FormMSG(15, "Reject")}`
                              )
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isUndoReject(this.selectedItem)
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-start align-items-center pd-1 menu-context",
                          on: {
                            click: function ($event) {
                              return _vm.onMenuItemClick(2.3)
                            },
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn bg-transparent border-0",
                              attrs: { size: "sm" },
                            },
                            [
                              _c(_vm.getLucideIcon(_vm.ICONS.UNDO_2.name), {
                                tag: "component",
                                attrs: {
                                  color: _vm.ICONS.UNDO_2.color,
                                  size: 16,
                                  "stroke-width": 2.25,
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", [
                            _vm._v(_vm._s(_vm.FormMSG(20, "Undo reject"))),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-start align-items-center pd-1 menu-context",
                      on: {
                        click: function ($event) {
                          return _vm.onMenuItemClick(3)
                        },
                      },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn bg-transparent border-0",
                          attrs: { size: "sm" },
                        },
                        [
                          _c(_vm.getLucideIcon(_vm.ICONS.EYE.name), {
                            tag: "component",
                            attrs: {
                              color: _vm.ICONS.EYE.color,
                              size: 16,
                              "stroke-width": 2.25,
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", [_vm._v(_vm._s(_vm.FormMSG(11, "See info")))]),
                    ],
                    1
                  ),
                  _vm.isShowActionByStatus(this.selectedItem)
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-start align-items-center pd-1 menu-context",
                          on: {
                            click: function ($event) {
                              return _vm.onMenuItemClick(4)
                            },
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn bg-transparent border-0",
                              attrs: { size: "sm" },
                            },
                            [
                              _c(_vm.getLucideIcon(_vm.ICONS.TRASH.name), {
                                tag: "component",
                                attrs: {
                                  color: _vm.ICONS.TRASH.color,
                                  size: 16,
                                  "stroke-width": 2.25,
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "d-flex" }, [
                            _vm._v(_vm._s(this.FormMSG(6, "Delete"))),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-start align-items-center pd-1 menu-context",
                      on: {
                        click: function ($event) {
                          return _vm.hideContextMenu()
                        },
                      },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn bg-transparent border-0",
                          attrs: { size: "sm" },
                        },
                        [
                          _c(_vm.getLucideIcon("ShieldClose"), {
                            tag: "component",
                            attrs: {
                              color: _vm.ICONS.PENCIL.color,
                              size: 16,
                              "stroke-width": 2.25,
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", [_vm._v(_vm._s(this.FormMSG(8, "Cancel")))]),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-success",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            title: _vm.FormMSG(16, "Informations"),
            "no-close-on-backdrop": "",
            "ok-variant": "success",
            "ok-only": "",
            "modal-class": "mui-animation",
            fade: false,
          },
          on: { ok: _vm.hideModal },
          model: {
            value: _vm.showDetailModalCell,
            callback: function ($$v) {
              _vm.showDetailModalCell = $$v
            },
            expression: "showDetailModalCell",
          },
        },
        [
          _c("div", { staticClass: "container-mobile animated fadeIn" }, [
            _c(
              "div",
              { staticClass: "content-detailsMyTimesheet-mobile" },
              [
                _c("show-detail-planning-cell", {
                  attrs: { showDetailData: _vm.showDetailData },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-danger",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            title: _vm.FormMSG(17, "Refuse this day"),
            "no-close-on-backdrop": "",
            "ok-variant": "danger",
            "ok-only": "",
            "modal-class": "mui-animation",
            fade: false,
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "w-100" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "float-right",
                          attrs: { variant: "danger", size: "sm" },
                          on: { click: _vm.finaliseTsDayReject },
                        },
                        [_vm._v(" OK ")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.successModalRejectedTsDay,
            callback: function ($$v) {
              _vm.successModalRejectedTsDay = $$v
            },
            expression: "successModalRejectedTsDay",
          },
        },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.FormMSG(18, "Enter your comment:"),
                "label-for": "comment",
                "label-cols": 4,
              },
            },
            [
              _c("b-form-textarea", {
                attrs: {
                  id: "comment",
                  placeholder: this.FormMSG(19, "Enter your remarks..."),
                  rows: "3",
                },
                model: {
                  value: _vm.rejectComment,
                  callback: function ($$v) {
                    _vm.rejectComment = $$v
                  },
                  expression: "rejectComment",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }