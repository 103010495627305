var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.error, warning: _vm.warning } },
    [
      _c("custom-loading-planning", {
        attrs: {
          loading: _vm.isLoading,
          label: this.FormMSG(40, "Prepare data of timesheet entry for crew"),
        },
      }),
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          { staticClass: "form" },
          [
            _vm.Timesheets
              ? _c(
                  "b-row",
                  { staticClass: "animated fadeIn" },
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "12", xl: "12" } },
                      [
                        _c(
                          "div",
                          { staticClass: "clearfix mb-2" },
                          [
                            _c(
                              "b-row",
                              [
                                _c("b-col", [
                                  _c(
                                    "h1",
                                    {
                                      class: [
                                        `${
                                          !_vm.$screen.sm
                                            ? "main-page-title"
                                            : ""
                                        }`,
                                        { "is-pwa": _vm.$isPwa() },
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              77,
                                              "Timesheets - Entry for crew"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm.groupActionCellTimeCards !==
                                _vm.planningView
                                  ? _c(
                                      "b-col",
                                      { attrs: { md: "auto" } },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            staticClass:
                                              "btn btn-success pull-right block-sm",
                                            on: {
                                              click: function ($event) {
                                                return _vm.showModal()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    3,
                                                    "Add to timesheet"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              [
                                _c("time-card-for-crew-modal", {
                                  attrs: {
                                    editData: _vm.editData,
                                    isOpen: _vm.Open,
                                    showModal: _vm.showModal,
                                    hideModal: _vm.hideModal,
                                    isActionCrud: _vm.isActionCrud,
                                    addDataFunc: _vm.addDataFunc,
                                    "external-dates": _vm.planningToSend,
                                    screenUsedTheForm: `entry-for-crew`,
                                    useFormInModal: _vm.useFormInModal,
                                  },
                                  on: {
                                    "add:Submitted": _vm.handleRefreshData,
                                    closeModal: _vm.closeModal,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              {
                                staticClass: "hide-on-tablet",
                                attrs: { cols: "12", xl: "12" },
                              },
                              [
                                _c(
                                  "b-card",
                                  { attrs: { "no-body": "" } },
                                  [
                                    _c(
                                      "b-tabs",
                                      {
                                        attrs: { card: "" },
                                        model: {
                                          value: _vm.currentExpenseTab,
                                          callback: function ($$v) {
                                            _vm.currentExpenseTab = $$v
                                          },
                                          expression: "currentExpenseTab",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-tab",
                                          {
                                            attrs: {
                                              title: this.FormMSG(
                                                79,
                                                "Time cards"
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.setTabAction(
                                                  _vm.timecards_One
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-text",
                                              [
                                                _c("header-timesheets", {
                                                  attrs: {
                                                    id: _vm.timecards_One,
                                                    "set-filter": (item) =>
                                                      _vm.setFilter(item),
                                                    filter: _vm.filter,
                                                    onSearch: _vm.onSearch,
                                                    selectDate:
                                                      _vm.allDataTimeCards,
                                                    formChanged: (
                                                      date,
                                                      idTable
                                                    ) =>
                                                      _vm.formChanged(
                                                        date,
                                                        idTable
                                                      ),
                                                    formChangedPlanningView: (
                                                      date,
                                                      idTable
                                                    ) =>
                                                      _vm.formChangedPlanningView(
                                                        date,
                                                        idTable
                                                      ),
                                                    groupByFilter:
                                                      _vm.groupActionCellTimeCards,
                                                    dataPlanningSelect:
                                                      _vm.dataPlanningSelect,
                                                    "show-modal": _vm.showModal,
                                                    isNavigateInitDate:
                                                      _vm.isNavigateInitDate,
                                                    dataGlobal: _vm.TimeCards,
                                                  },
                                                  on: {
                                                    "month:Selected":
                                                      _vm.handleSelectedFormFilter,
                                                    "day:Selected":
                                                      _vm.handleSelectedDay,
                                                    handleSelectDepartementChange:
                                                      _vm.handleSelectDepartementChange,
                                                    setIsNavigateInitDate:
                                                      _vm.setIsNavigateInitDate,
                                                    resetDataAfterAddUpdate:
                                                      _vm.resetDataAfterAddUpdate,
                                                    "refersh-on-delete-not-submitted-all":
                                                      _vm.handleRefreshData,
                                                  },
                                                }),
                                                _vm.miniSpinnerLoadingTimeCards
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "clearfix mb-0",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-center",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              [
                                                                _c(
                                                                  "b-spinner",
                                                                  {
                                                                    attrs: {
                                                                      variant:
                                                                        "primary",
                                                                      label:
                                                                        "Text Centered",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c("div", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  this.FormMSG(
                                                                    78,
                                                                    "Prepare All data"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _c("div", [
                                                      _vm.groupActionCellTimeCards ===
                                                      _vm.planningView
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "planning-view-tables",
                                                                {
                                                                  attrs: {
                                                                    id: _vm.timecards_One,
                                                                    selectDate:
                                                                      _vm.allDataTimesheets,
                                                                    hover:
                                                                      _vm.hover,
                                                                    currentpage:
                                                                      _vm.currentPage,
                                                                    perpage:
                                                                      _vm.perPage,
                                                                    hv: _vm.hv,
                                                                    tsfields:
                                                                      _vm.fieldsTimeCardsPlanning,
                                                                    timesheets:
                                                                      _vm.TimeCards,
                                                                    DateDefaultSelect:
                                                                      _vm.DateDefaultSelect,
                                                                    open: _vm.Open,
                                                                    isPersistDataPlanning:
                                                                      _vm.isPersistDataPlanning,
                                                                  },
                                                                  on: {
                                                                    setDataSizeModalPlanning:
                                                                      _vm.setDataSizeModalPlanning,
                                                                    sendDataModalPlanning:
                                                                      _vm.sendDataModalPlanning,
                                                                    showModalPlanningContextUpdate:
                                                                      _vm.showModalPlanningContextUpdate,
                                                                    changeDateDefaultSelect:
                                                                      _vm.changeDateDefaultSelect,
                                                                    getDataFromPagePlanning:
                                                                      _vm.getDataFromPagePlanning,
                                                                    validateTsDays:
                                                                      _vm.validateTsDays,
                                                                    deleteItemPlanning:
                                                                      _vm.deleteItemPlanning,
                                                                    sendForValidationPlanning:
                                                                      _vm.sendForValidationPlanning,
                                                                    handleRefreshReject:
                                                                      _vm.handleRefreshReject,
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-modal",
                                                                {
                                                                  staticClass:
                                                                    "modal-success",
                                                                  attrs: {
                                                                    "header-class":
                                                                      "header-class-modal-doc-package",
                                                                    title:
                                                                      _vm.FormMSG(
                                                                        17,
                                                                        "Success"
                                                                      ),
                                                                    "ok-variant":
                                                                      "success",
                                                                    "ok-only":
                                                                      "",
                                                                    "modal-class":
                                                                      "mui-animation",
                                                                    fade: false,
                                                                  },
                                                                  on: {
                                                                    ok: function (
                                                                      $event
                                                                    ) {
                                                                      _vm.successModal = false
                                                                    },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.successModal,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.successModal =
                                                                          $$v
                                                                      },
                                                                    expression:
                                                                      "successModal",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          18,
                                                                          "The timesheet has been validated!"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm.groupActionCellTimeCards ===
                                                          _vm.groupByDate
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "timesheets-per-date-view",
                                                                {
                                                                  attrs: {
                                                                    id: _vm.timecards_One,
                                                                    groupByFilter:
                                                                      _vm.groupActionCellTimeCards,
                                                                    hover:
                                                                      _vm.hover,
                                                                    currentpage:
                                                                      _vm.currentPage,
                                                                    perpage:
                                                                      _vm.perPage,
                                                                    hv: _vm.hv,
                                                                    timeCards:
                                                                      _vm.TimeCards,
                                                                    tsfields:
                                                                      _vm.fieldsTimeCards,
                                                                    deleteAction:
                                                                      (item) =>
                                                                        _vm.deleteAction(
                                                                          item,
                                                                          _vm.timecards_One
                                                                        ),
                                                                    totalAllPageRecords:
                                                                      _vm.totalAllPageRecords,
                                                                    isActionCrud:
                                                                      _vm.isActionCrud,
                                                                  },
                                                                  on: {
                                                                    refreshRequested:
                                                                      _vm.handleRefreshData,
                                                                    changePageSelectLimit:
                                                                      _vm.changePageSelectLimit,
                                                                    handleNextPerDate:
                                                                      _vm.handleNextPerDate,
                                                                    handlePreviousPerDate:
                                                                      _vm.handlePreviousPerDate,
                                                                    refreshDataItemPerDate:
                                                                      _vm.refreshDataItemPerDate,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _c("div", [
                                                            _vm.TimeCards
                                                              .length === 0
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex flex-row justify-content-center align-items-center border",
                                                                    staticStyle:
                                                                      {
                                                                        height:
                                                                          "40px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          this.FormMSG(
                                                                            91,
                                                                            "No data"
                                                                          )
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "div",
                                                                  _vm._l(
                                                                    _vm.TimeCards,
                                                                    function (
                                                                      item
                                                                    ) {
                                                                      return _c(
                                                                        "div",
                                                                        {
                                                                          key: _vm.generateSecureId(
                                                                            `${
                                                                              item.id +
                                                                              Math.random()
                                                                            }`
                                                                          ),
                                                                          staticClass:
                                                                            "animated fadeIn",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "timesheets-efc-tables",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  id: _vm.timecards_One,
                                                                                  hover:
                                                                                    _vm.hover,
                                                                                  timesheets:
                                                                                    item.data,
                                                                                  tsfields:
                                                                                    _vm.fieldsTimeCards,
                                                                                  currentpage:
                                                                                    _vm.currentPage,
                                                                                  perpage:
                                                                                    _vm.perPage,
                                                                                  hv: _vm.hv,
                                                                                  rowclicked:
                                                                                    (
                                                                                      item
                                                                                    ) =>
                                                                                      _vm.rowClicked(
                                                                                        item
                                                                                      ),
                                                                                  i: _vm.generateSecureId(
                                                                                    item.id +
                                                                                      item.date +
                                                                                      item.fullName +
                                                                                      item.departmentName
                                                                                  ),
                                                                                  groupByFilter:
                                                                                    _vm.groupActionCellTimeCards,
                                                                                  deleteAction:
                                                                                    (
                                                                                      item,
                                                                                      idTable
                                                                                    ) =>
                                                                                      _vm.deleteAction(
                                                                                        item,
                                                                                        idTable
                                                                                      ),
                                                                                  hideModal:
                                                                                    _vm.hideModal,
                                                                                },
                                                                              on: {
                                                                                "add:Submitted":
                                                                                  _vm.handleRefreshData,
                                                                              },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                          ]),
                                                    ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-tab",
                                          {
                                            attrs: {
                                              title: this.FormMSG(
                                                80,
                                                "Timesheets"
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.setTabAction(
                                                  _vm.timesheets_Two
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-text",
                                              [
                                                _c("header-timesheets", {
                                                  attrs: {
                                                    id: _vm.timesheets_Two,
                                                    "set-filter": (item) =>
                                                      _vm.setFilter(item),
                                                    useComponent: true,
                                                    filter: _vm.filter,
                                                    selectDate:
                                                      _vm.allDataTimesheets,
                                                    onSearch: _vm.onSearch,
                                                    formChanged: (
                                                      date,
                                                      idTable
                                                    ) =>
                                                      _vm.formChanged(
                                                        date,
                                                        idTable
                                                      ),
                                                    groupByFilter:
                                                      _vm.groupActionCellTimesheets,
                                                    isNavigateInitDate:
                                                      _vm.isNavigateInitDate,
                                                  },
                                                  on: {
                                                    "month:Selected":
                                                      _vm.handleSelectedFormFilter,
                                                    setIsNavigateInitDate:
                                                      _vm.setIsNavigateInitDate,
                                                  },
                                                }),
                                                _vm.miniSpinnerLoadingTimeSheet
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "clearfix mb-0",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-center",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              [
                                                                _c(
                                                                  "b-spinner",
                                                                  {
                                                                    attrs: {
                                                                      variant:
                                                                        "primary",
                                                                      label:
                                                                        "Text Centered",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c("div", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  this.FormMSG(
                                                                    78,
                                                                    "Prepare All data"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _c("div", [
                                                      _vm.Timesheets.length ===
                                                      0
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-row justify-content-center align-items-center border",
                                                              staticStyle: {
                                                                height: "40px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    this.FormMSG(
                                                                      91,
                                                                      "No data"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "div",
                                                            _vm._l(
                                                              _vm.Timesheets,
                                                              function (item) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: _vm.generateSecureId(
                                                                      `${
                                                                        item.id +
                                                                        Math.random()
                                                                      }`
                                                                    ),
                                                                    staticClass:
                                                                      "animated fadeIn",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "timesheets-efc-tables",
                                                                      {
                                                                        attrs: {
                                                                          id: _vm.timesheets_Two,
                                                                          isTimesheet: true,
                                                                          hover:
                                                                            _vm.hover,
                                                                          timesheets:
                                                                            item.data,
                                                                          tsfields:
                                                                            _vm.fieldsTimesheets,
                                                                          currentpage:
                                                                            _vm.currentPage,
                                                                          perpage:
                                                                            _vm.perPage,
                                                                          hv: _vm.hv,
                                                                          rowclicked:
                                                                            (
                                                                              item
                                                                            ) =>
                                                                              _vm.rowClicked(
                                                                                item
                                                                              ),
                                                                          i: _vm.generateSecureId(
                                                                            item.id +
                                                                              item.date +
                                                                              item.fullName +
                                                                              item.departmentName
                                                                          ),
                                                                          useComponent: true,
                                                                          groupByFilter:
                                                                            _vm.groupActionCellTimesheets,
                                                                          deleteAction:
                                                                            (
                                                                              item,
                                                                              idTable
                                                                            ) =>
                                                                              _vm.deleteAction(
                                                                                item,
                                                                                idTable
                                                                              ),
                                                                          hideModal:
                                                                            _vm.hideModal,
                                                                        },
                                                                        on: {
                                                                          "add:Submitted":
                                                                            _vm.handleRefreshData,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                    ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }