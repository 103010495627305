var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "clearfix mb-0" },
    [
      _c(
        "b-row",
        { staticClass: "pb-1 pt-0 pl-4" },
        [
          _c(
            "b-form-radio-group",
            {
              staticClass: "mb-3",
              attrs: { "text-field": "name" },
              on: { change: (item) => _vm.setFilter(item) },
              model: {
                value: _vm.setGroupByFilter,
                callback: function ($$v) {
                  _vm.setGroupByFilter = $$v
                },
                expression: "setGroupByFilter",
              },
            },
            [
              _c(
                "b-form-radio",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.id === _vm.timecards_One,
                      expression: "id === timecards_One",
                    },
                  ],
                  attrs: { value: _vm.planningView },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "mr-4",
                      staticStyle: { "margin-top": "1px" },
                    },
                    [_vm._v(_vm._s(this.FormMSG(9, "Planning view")))]
                  ),
                ]
              ),
              _c("b-form-radio", { attrs: { value: _vm.groupByDate } }, [
                _c(
                  "div",
                  { staticClass: "mr-4", staticStyle: { "margin-top": "1px" } },
                  [_vm._v(_vm._s(this.FormMSG(2, "Per date")))]
                ),
              ]),
              _c("b-form-radio", { attrs: { value: _vm.groupByPerson } }, [
                _c(
                  "div",
                  { staticClass: "mr-4", staticStyle: { "margin-top": "1px" } },
                  [_vm._v(_vm._s(this.FormMSG(1, "Per person")))]
                ),
              ]),
              _c("b-form-radio", { attrs: { value: _vm.groupByStatus } }, [
                _c(
                  "div",
                  { staticClass: "mr-4", staticStyle: { "margin-top": "1px" } },
                  [_vm._v(_vm._s(this.FormMSG(3, "Per status")))]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.setGroupByFilter === _vm.planningView
        ? _c(
            "div",
            [
              _c(
                "b-row",
                { attrs: { "align-h": "between" } },
                [
                  _c(
                    "b-col",
                    { staticClass: "pb-0 ml-1" },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.focus",
                                modifiers: { focus: true },
                              },
                            ],
                            attrs: {
                              type: "text",
                              placeholder: this.FormMSG(
                                11,
                                "Type to search..."
                              ),
                              id: "filterInput",
                              autocomplete: "off",
                            },
                            on: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.onSearch(
                                  _vm.filter,
                                  _vm.notReset,
                                  _vm.id
                                )
                              },
                            },
                            model: {
                              value: _vm.filter,
                              callback: function ($$v) {
                                _vm.filter =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "filter",
                            },
                          }),
                          _c(
                            "b-input-group-append",
                            { staticClass: "cursor-pointer" },
                            [
                              _c(
                                "b-input-group-text",
                                { staticClass: "btn-search" },
                                [
                                  _vm.filter.length === 0
                                    ? _c(
                                        _vm.getLucideIcon(
                                          _vm.ICONS.SEARCH.name
                                        ),
                                        {
                                          tag: "component",
                                          staticClass: "icon",
                                          attrs: {
                                            color: _vm.ICONS.SEARCH.color,
                                            size: 16,
                                            "stroke-width": 2.5,
                                          },
                                        }
                                      )
                                    : _c(_vm.getLucideIcon(_vm.ICONS.X.name), {
                                        tag: "component",
                                        staticClass: "icon",
                                        attrs: {
                                          color: _vm.ICONS.X.color,
                                          size: 16,
                                          "stroke-width": 2.5,
                                        },
                                        on: { click: () => _vm.initReset() },
                                      }),
                                ],
                                1
                              ),
                              _c(
                                "b-tooltip",
                                {
                                  attrs: {
                                    target: "filterInput",
                                    placement: "top",
                                    triggers: "focus",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        this.FormMSG(
                                          12,
                                          "Type 'Enter' to launch search"
                                        )
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "pb-0 ml-1" },
                    [
                      _c(
                        "b-form-group",
                        [
                          _c("v-select", {
                            staticClass: "w-100",
                            attrs: {
                              placeholder: "Select departement",
                              options: _vm.allDepartements,
                              label: "message",
                              clearable: true,
                              reduce: (option) => option.value,
                            },
                            on: {
                              "option:selected": _vm.handleSelectDepartement,
                            },
                            model: {
                              value: _vm.department,
                              callback: function ($$v) {
                                _vm.department = $$v
                              },
                              expression: "department",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { attrs: { "align-h": "between" } },
                [
                  _c(
                    "b-col",
                    { staticClass: "pb-2 ml-1", attrs: { cols: "7" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex flex-row",
                          staticStyle: { gap: "10px" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-row justify-content-start align-items-center gp-5",
                            },
                            [
                              _c("div", {
                                staticClass: "status-not-submitted",
                              }),
                              _c("div", { staticClass: "col-form-label" }, [
                                _vm._v(
                                  _vm._s(_vm.FormMSG(18, "Not submitted"))
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-row justify-content-start align-items-center gp-5",
                            },
                            [
                              _c("div", { staticClass: "status-info" }),
                              _c("div", [
                                _vm._v(_vm._s(_vm.FormMSG(13, "Submitted"))),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-row justify-content-start align-items-center gp-5",
                            },
                            [
                              _c("div", { staticClass: "status-pending" }),
                              _c("div", { staticClass: "col-form-label" }, [
                                _vm._v(
                                  _vm._s(_vm.FormMSG(19, "Validated HOD"))
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-row justify-content-start align-items-center gp-5",
                            },
                            [
                              _c("div", { staticClass: "status-validated" }),
                              _c("div", { staticClass: "col-form-label" }, [
                                _vm._v(_vm._s(_vm.FormMSG(15, "Validated"))),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-row justify-content-start align-items-center gp-5",
                            },
                            [
                              _c("div", { staticClass: "status-refused" }),
                              _c("div", { staticClass: "col-form-label" }, [
                                _vm._v(_vm._s(_vm.FormMSG(16, "Refused"))),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex flex-row justify-content-end pb-2 w-100 gap-1",
                      attrs: { cols: "4" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-end w-auto" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                variant: "danger",
                                disabled:
                                  _vm.countDataToUpdate(
                                    _vm.dataPlanningSelect
                                  ) === "(00)"
                                    ? true
                                    : false,
                              },
                              on: { click: _vm.onOpenModalToAllDelete },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.FormMSG(20, "Delete all not submitted ")
                                ) +
                                  " " +
                                  _vm._s(
                                    _vm.countDataToUpdate(
                                      _vm.dataPlanningSelect
                                    )
                                  )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-end w-auto" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                disabled:
                                  _vm.dataPlanningSelect === undefined
                                    ? true
                                    : _vm.dataPlanningSelect.length === 0
                                    ? true
                                    : false,
                              },
                              on: { click: _vm.showModal },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.FormMSG(10, "Add Selected Planning"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "b-row",
                { attrs: { "align-h": "between" } },
                [
                  !_vm.useComponent && _vm.groupByFilter === _vm.groupByPerson
                    ? _c(
                        "b-col",
                        { staticClass: "pb-3 ml-1", attrs: { cols: "6" } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.focus",
                                    modifiers: { focus: true },
                                  },
                                ],
                                attrs: {
                                  type: "text",
                                  id: "filterInput",
                                  placeholder: "Type to Search",
                                },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.onSearch(
                                      _vm.filter,
                                      _vm.notReset,
                                      _vm.id
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.filter,
                                  callback: function ($$v) {
                                    _vm.filter =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "filter",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                { staticClass: "cursor-pointer" },
                                [
                                  _c(
                                    "b-input-group-text",
                                    { staticClass: "btn-search" },
                                    [
                                      _vm.filter.length === 0
                                        ? _c(
                                            _vm.getLucideIcon(
                                              _vm.ICONS.SEARCH.name
                                            ),
                                            {
                                              tag: "component",
                                              staticClass: "icon",
                                              attrs: {
                                                color: _vm.ICONS.SEARCH.color,
                                                size: 16,
                                                "stroke-width": 2.5,
                                              },
                                            }
                                          )
                                        : _c(
                                            _vm.getLucideIcon(_vm.ICONS.X.name),
                                            {
                                              tag: "component",
                                              staticClass: "icon",
                                              attrs: {
                                                color: _vm.ICONS.X.color,
                                                size: 16,
                                                "stroke-width": 2.5,
                                              },
                                              on: {
                                                click: () => _vm.initReset(),
                                              },
                                            }
                                          ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        target: "filterInput",
                                        placement: "top",
                                        triggers: "focus",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            this.FormMSG(
                                              12,
                                              "Type 'Enter' to launch search"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.useComponent && _vm.groupByFilter === _vm.groupByPerson
                    ? _c(
                        "b-col",
                        { staticClass: "pb-3 ml-1", attrs: { cols: "6" } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.focus",
                                    modifiers: { focus: true },
                                  },
                                ],
                                attrs: {
                                  type: "text",
                                  id: "filterInput-timesheets-two",
                                  placeholder: this.FormMSG(
                                    4,
                                    "Type to Search"
                                  ),
                                },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.onSearch(
                                      _vm.filter,
                                      _vm.notReset,
                                      _vm.id
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.filter,
                                  callback: function ($$v) {
                                    _vm.filter =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "filter",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                { staticClass: "cursor-pointer" },
                                [
                                  _c(
                                    "b-input-group-text",
                                    { staticClass: "btn-search" },
                                    [
                                      _vm.filter.length === 0
                                        ? _c(
                                            _vm.getLucideIcon(
                                              _vm.ICONS.SEARCH.name
                                            ),
                                            {
                                              tag: "component",
                                              staticClass: "icon",
                                              attrs: {
                                                color: _vm.ICONS.SEARCH.color,
                                                size: 16,
                                                "stroke-width": 2.5,
                                              },
                                            }
                                          )
                                        : _c(
                                            _vm.getLucideIcon(_vm.ICONS.X.name),
                                            {
                                              tag: "component",
                                              staticClass: "icon",
                                              attrs: {
                                                color: _vm.ICONS.X.color,
                                                size: 16,
                                                "stroke-width": 2.5,
                                              },
                                              on: {
                                                click: () => _vm.initReset(),
                                              },
                                            }
                                          ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        target: "filterInput-timesheets-two",
                                        placement: "top",
                                        triggers: "focus",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            this.FormMSG(
                                              12,
                                              "Type 'Enter' to launch search"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.useComponent && _vm.groupByFilter === _vm.groupByDate
                    ? _c(
                        "b-col",
                        { staticClass: "pb-3 ml-1", attrs: { cols: "6" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("v-select", {
                                attrs: {
                                  clearable: false,
                                  label: "text",
                                  options: _vm.optionsMonth,
                                },
                                on: {
                                  "option:selected": (e) =>
                                    _vm.selecteMonth(e, _vm.id),
                                },
                                model: {
                                  value: _vm.currentForm,
                                  callback: function ($$v) {
                                    _vm.currentForm = $$v
                                  },
                                  expression: "currentForm",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.useComponent && _vm.groupByFilter === _vm.groupByDate
                    ? _c(
                        "b-col",
                        { staticClass: "pb-3 ml-1", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          clearable:
                                            _vm.currentForm.value ===
                                            _vm.dateNow.value
                                              ? false
                                              : true,
                                          label: "text",
                                          options: _vm.MonthList,
                                        },
                                        on: {
                                          "option:selected": (e) =>
                                            _vm.selecteMonth(e, _vm.id),
                                        },
                                        model: {
                                          value: _vm.currentForm,
                                          callback: function ($$v) {
                                            _vm.currentForm = $$v
                                          },
                                          expression: "currentForm",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          clearable: false,
                                          label: "text",
                                          options: _vm.flattenDays,
                                        },
                                        on: {
                                          "option:selected": (e) =>
                                            _vm.handleSelectedDay(e),
                                        },
                                        model: {
                                          value: _vm.currentDay,
                                          callback: function ($$v) {
                                            _vm.currentDay = $$v
                                          },
                                          expression: "currentDay",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              !_vm.useComponent && _vm.groupByFilter !== _vm.groupByDate
                ? _c(
                    "b-row",
                    { attrs: { "align-h": "between" } },
                    [
                      _c("b-col", { staticClass: "pl-4", attrs: { md: "6" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "fw-400",
                            staticStyle: {
                              "font-size": "13px",
                              color: "rgba(6, 38, 62, 0.65)",
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(
                                  this.FormMSG(
                                    8,
                                    "* Click on a row to view more informations or edit"
                                  )
                                ) +
                                "\n\t\t\t\t"
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
      _vm.openModalDeleteAll
        ? _c(
            "b-modal",
            {
              attrs: {
                "no-close-on-backdrop": "",
                title: _vm.FormMSG(
                  21,
                  "Do you want to remove these timesheets"
                ),
                "modal-class": "mui-animation",
                fade: false,
              },
              on: { hidden: _vm.handleCloseAllDeleteModal },
              scopedSlots: _vm._u(
                [
                  {
                    key: "modal-footer",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row justify-content-end w-100 gap-1 w-1",
                          },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "border-1",
                                    attrs: {
                                      variant: "outline-primary",
                                      size: "sm",
                                    },
                                    on: {
                                      click: _vm.handleCloseAllDeleteModal,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.FormMSG(22, "No")) + " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "border-1",
                                    attrs: {
                                      variant: "outline-danger",
                                      size: "sm",
                                    },
                                    on: { click: _vm.handleDeleteAllPlanning },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.FormMSG(23, "Yes")) + " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                142716676
              ),
              model: {
                value: _vm.openModalDeleteAll,
                callback: function ($$v) {
                  _vm.openModalDeleteAll = $$v
                },
                expression: "openModalDeleteAll",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex flex-column gap-1" },
                _vm._l(_vm.groupByData(), function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass:
                        "d-flex flex-row align-items-center w-100 border-rd p-2",
                    },
                    [
                      _c("div", { staticClass: "w-50" }, [
                        _c("label", [_vm._v(_vm._s(item.fullName))]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column border-lf w-50" },
                        _vm._l(item.data, function (children, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "text-1" },
                            [_vm._v(_vm._s(children.date))]
                          )
                        }),
                        0
                      ),
                    ]
                  )
                }),
                0
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }